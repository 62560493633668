(function() {
    var g_widget_url = 'REFERRIZER_WIDGET_URL';

    var script_tag = document.createElement('script');
    script_tag.setAttribute("type", "text/javascript");

    if(typeof window.refFormsWidget == 'undefined') {
        window.refFormsWidget = {};
        script_tag.setAttribute("src", g_widget_url + "/offer/forms-widget.js?v=" + Date.now());
    }

    // Try to find the head, otherwise default to the documentElement
    (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);

})();
